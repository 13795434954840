/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


$border_color: #ededed;

body {
    direction: rtl;
    background-color: white;

    font-family: 'Noor';
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

ion-back-button {
    direction: ltr;
}

ion-refresher {
    top: 40px;
}

ion-content {
    --background: white;
    background: white;
}

ion-content.safe_area {
    --padding-top: 44px;
    --padding-bottom: 12px;
}

#bg_wrapper {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 100px;
    z-index: -1;
}

.spacer {
    width: 100%;
    height: 40px;
    ;
}

.no_content {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
}

ion-toolbar {
    --background: #ffffff0f;
    --color: white;
}

ion-toolbar .logo {
    width: 100px;
    padding-right: 12px;
}

ion-modal {
    // --ion-background-color: #ffffff0f;
    // --ion-background-color: var(--side_menu_bg);
}

ion-modal ion-content {
    --offset-top: 44px !important;
    --offset-bottom: unset !important;
    overflow: auto !important;
}

dzyni-button {
    cursor: pointer;
}

.dzyni_button,
.action_button,
.square_button {
    transition: all 0.2s ease;
}

.dzyni_button:hover,
.dzyni_button:focus,
.square_button:hover,
.square_button:focus {
    // transform: scale(1.05);
    box-shadow: 0px 0px 4px #00000020;
}

.action_button:hover,
.action_button:focus {

}

ion-popover p {
    color: black;
    background-color: white;
    margin: 0;
    padding: 12px;
}

.flexy {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexy div:not(.request_status_tag) {
    // width: 100%;
}

.tag_div {
    margin: 0 4px;
    font-size: 12px;
    border: 1px solid #D9D9D9;
    color: black;
    padding: 4px 12px;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 8px;
}

.tag_div:hover {
    color: var(--theme_primary_color);
    border-color: var(--theme_primary_color);
}

.tag_div.no_hover:hover {
    color: black;
    border-color: #D9D9D9;
}

.spaced {
    justify-content: space-between;
}

.safari-popup-alert img {
    width: 500px !important;
}

.pins {
    column-count: 1;
    padding: 7px;
    margin-left: 0;
    width: 100%;
}

.pin {
    width: 100%;
    display: inline-block;
    margin: 0 2px 10px;
    // padding: 5px;
    // background: #f8f8ff;
}

.my-custom-class .alert_payed {
    font-weight: bolder;
}

.alert-message img {
    display: block;
    width: 100px;
    margin: 0 auto;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    // background: #eaeaea;
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    // background: rgb(192, 192, 192);
    background: transparent;
    border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


ion-item-option {
    border-radius: 16px;
    margin: 4px 0;
}

.item-sliding-active-slide.item-sliding-active-options-start .item-options-start,
.item-sliding-active-slide.item-sliding-active-options-end ion-item-options:not(.item-options-start) {
    width: auto;
}

.comission_text {
    display: block;
    font-size: 12px;
    color: #cccccc;
}

.help_button {
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px #00000050;
    font-weight: bold;
    color: var(--theme_primary_color);
    margin-right: auto;
    display: flex;
    align-items: center;
}

.dzyni_button {
    border-radius: 12px;
    font-weight: bold;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 16px;

    display: block;
    background: var(--theme_primary_color);
    margin-top: 12px;

    transition: all 0.2s ease;

    cursor: pointer;
}

.dzyni_button.light {
    background: transparent;
    color: var(--theme_primary_color);
}

.dzyni_button.cyan {
    background: transparent;
    color: #00bde2;
}

.dzyni_button:hover {
    filter: brightness(1.2);
}

.action_button svg {
    display: inline-block;
    width: 16px;
    margin-left: 4px;
    fill: white;
}

.action_button,
.chat_to_request {
    background: black;
    color: white;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 4px 0;
    padding: 16px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.action_button:hover,
.chat_to_request:hover {
    background: var(--theme_primary_color);
}

.action_button.primary {
    background: var(--theme_primary_color);
}

.action_button.light {
    background: rgb(244, 244, 244);
    color: var(--theme_primary_color);
}

.action_button.primary:hover {
    background: var(--theme_primary_color_dar);
}

.action_button.primary,
.action_button.primary p,
.action_button.primary span {
    color: white;
}

.action_button.full {
    display: flex !important;
}

.action_button.success {
    background: #45c962;
}

.action_button.danger {
    background: transparent;
    color: #ff5f5f;
    background: var(--theme_bg_color);
}

.action_button.disabled {
    filter: opacity(20%);
}

.action_button.invert {
    background: transparent;
    border: 1px solid var(--theme_primary_color);
    color: var(--theme_primary_color);
}

.action_button.invert:hover,
.action_button.invert:focus {
    background: #ededed;
}

.icon_button {
    background: transparent;
    color: black;
}

.icon_button:hover {
    background: #f5f5f5;
}

.square_button {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: rgb(243, 243, 243);
    margin: 0px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.square_button.active_icon {
    background: var(--theme_primary_color);
}

.square_button.active_icon svg {
    filter: brightness(10);
    fill: white;
}

.user_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 4px 0;
}

.user_wrapper .user_img {
    width: 32px;
    height: 32px;
    margin-left: 12px;
    border-radius: 32px;
}

.user_wrapper .user_name {
    font-size: 18px;
    margin: 0;
    text-align: right;
}

.chat_to_request {
    background-color: #c945c2;
}

.this_is_chat {
    background-color: #c945c2;
    width: 50%;
    color: white;
    border-radius: 100px;
}

.job_subtitle {
    display: block;
    font-size: 10px;
    opacity: 1;
    margin-bottom: -4px;
    font-weight: normal;
}

// request_category_item (used in home and new request)
.request_category_item.submitted {
    --background: transparent;
    border: none;
    --border: none;
    // display: flex;
    // justify-content: start;
    // align-items: center;
    padding: 18px 0;
    border-radius: 16px;
    margin: 4px 0;
    color: white;
    cursor: pointer;
}

.request_category_item {
    border: 1px solid #cccccc;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px;
    border-radius: 16px;
    margin: 12px 0;
    color: black;
    cursor: pointer;
    --padding-start: 0px;
    transition: outline 0.2s ease-in-out;
    -webkit-transition: outline 0.2s ease-in-out;
}

.new_request_category_item {
    outline: 1px solid #cccccc;
}

.new_request_category_item:hover {
    outline: 2px solid var(--theme_primary_color);
}

.request_category_item .user_request_icon,
.request_category_item img {
    margin-left: 16px;
    width: 64px;
}

.request_category_item .user_request_icon {
    margin-right: 24px;
}

.request_category_title {
    font-weight: bold;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user_request_item {
    background-color: var(--theme_primary_color);
    color: white;
    cursor: pointer;
    transition: filter 0.2s ease-in-out;
    -webkit-transition: filter 0.2s ease-in-out;
}

.user_request_item:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 4px #00000020;
}

.user_request_item .request_id {
    font-size: 12px;
    opacity: 0.5;
}

.user_request_item .request_date {
    font-size: 12px;
    color: #7FFADF;
}

.chat_request_item {}

.request_status_tag {
    max-width: 120px;
    text-align: center;
    font-size: 12px;
    color: black;
    border-radius: 50px;
    display: inline-block;
    padding: 2px 10px;
    margin-top: 8px;
    font-weight: bold;
}

.request_status_tag.inside {
    display: block;
    font-size: 12px;
    color: black;
    border-radius: 50px;
    display: inline-block;
    margin-top: 0px;
    padding: 2px 10px;
    font-weight: bold;
}

.request_status_tag.request_in_review {
    background-color: white;
}

.request_status_tag.request_new {
    background-color: var(--theme_primary_color);
    color: white;
}

.request_status_tag.request_has_proposals {
    background-color: #7FFADF;
}

.request_status_tag.request_started {
    background-color: #0F81D7;
    color: white;
}

.request_status_tag.request_pending_completion {
    background-color: #fad57f;
}

.request_status_tag.request_pending_payment {
    background-color: #31f7c2;
}

.request_status_tag.request_completed {
    background-color: #40CBD8;
    color: white;
}

.request_status_tag.request_chat {
    background-color: black;
    color: white;
}

.request_status_tag.chat_user_tag {
    background-color: #00000050;
    color: white;
    margin-right: 4px;
}

.request_status_tag.request_archived {
    background-color: rgb(228, 77, 77);
    color: white;
}

.refresh_button {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #ececec;
    cursor: pointer;
    width: 100px;
    padding-right: 8px;
    float: left;
}

.refresh_icon {
    width: 24px;
    background-color: #ececec;
    padding: 4px;
}

.order-alert .alert-input.sc-ion-alert-md {
    font-weight: bold;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 20px;
}

.order-alert-success {
    font-weight: bold !important;
}

//   Settings
.back_button {
    display: inline-block;
    background-color: var(--theme_bg_color);
    padding: 4px 8px;
    border-radius: 4px;
    margin: 40px 0 20px;
    cursor: pointer;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    cursor: pointer;
}

.back_button:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 4px #00000020;
}

.user_name {
    margin-top: 4px;
    font-weight: bold;
    font-size: 30px;
    color: black;
}

.user_icon {
    width: 32px;
    height: 32px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-weight: bold;
    font-size: 30px;
    cursor: pointer;
}

.request_detail_title {
    font-size: 14px;
    margin: 20px auto;
    color: black;
}

.request_detail_body {
    background-color: #F5F5F5;
    border-radius: 16px;
    font-weight: bold;
    padding: 16px;
    display: inline-block;
    color: black;
}

.request_detail_body p {
    white-space: pre-wrap;
}

.request_detail_body.in_settings {
    padding: 8px;
    display: block;
}

.samples_wrapper {
    overflow-x: scroll;
    white-space: nowrap;
}

.sample_div {
    width: 80px;
    height: 80px;
    display: inline-block;
    margin-left: 4px;
    border-radius: 8px;
    cursor: pointer;
}

.complete-custom-class .warning {
    color: red;
}

.profile_icon,
.chat_icon {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-weight: bolder;
    border-radius: 32px;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    cursor: pointer;
}

.chat_icon {
    border: none;
}

// FORM
.progress_bar {
    height: 4px;
    width: 0%;
    position: absolute;
    bottom: 10%;
    border-radius: 100px;
    background: rgb(23, 138, 216);
    background: linear-gradient(90deg, rgba(23, 138, 216, 1) 0%, rgba(162, 48, 222, 1) 100%);
}

.actions_section {
    background-color: #141416;
    width: 100%;
    position: absolute;
    bottom: 0;
}

input,
text-area {
    color: black;
}

.input_div {
    color: white;
}

.input_title {
    color: #B1B5C3;
    margin: 0;
    margin-bottom: 8px;
}

.input_subtitle {
    color: #7a7b7d;
    margin: 0;
    margin-bottom: 8px;
}

.items_wrapper {
    overflow-y: scroll;
    height: 50vh;

    padding-bottom: 100px;
}

.logo_wrapper {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
}

.logo_wrapper .logo {
    width: 100px;
}

.chat_icon_wrapper {
    position: fixed;
    left: 32px;
    bottom: 32px;
    cursor: pointer;
    z-index: 999999;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding: 8px;
    border-radius: 1000px;
    width: 120px;
    -webkit-transition: filter 0.3s;
    transition: filter 0.3s;
}

.chat_icon_wrapper:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 4px #00000020;
}

.chat_icon_wrapper p {
    margin: 0 !important;
    font-weight: bold;
    color: white;
    margin-left: 2px;
}

.chat_icon_wrapper img {
    width: 24px;
    margin-right: 2px;
}

input,
textarea,
.input_item,
.font_item {
    width: 100%;
    padding: 12px;
    border-radius: 16px;
    // background-color: var(--request_black_color);
    border: none;
    // color: white;
    // margin-bottom: 16px;
    background-color: white;
}

textarea:focus,
input:focus {
    outline: none;
}

.input_item {
    background-color: transparent;
    border: 1px solid #777E90;
    padding: 4px 12px;
}

.input_item_selected {
    border-color: white;
}

.attachment_img {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    display: inline-block;
    margin-left: 4px;
}

// FORM END

#logo_circle {
    width: 32px;
    margin: 0 auto;
    display: block;
}

.order_expanded_details {
    margin: 8px 0;
    border-bottom: 1px solid rgb(168, 168, 168);
}

.order_expanded_details ion-col {
    text-align: right !important;
}

.order_expanded_details p {
    font-weight: normal;
}

.order_arrow.flipped,
.flipped {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}


// Chat styles
.message_text {
    font-size: 14px;
    font-weight: bold;
    color: black;
    margin: 0;
    white-space: pre-wrap;
}


.my-message .message_text a {
    color: #69fffc !important;
}

.user_message,
.other_message {
    display: inline-block;
    padding: 4px 8px;
    background-color: black;
    color: white;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    word-wrap: break-word;
}

.system_message {
    text-align: center;
    width: 50%;
    padding: 8px;
    border-radius: 100px;
    background-color: #f0f0f0;
    margin: 4px auto;
}

.system_message .message_text {
    font-size: 12px;
    color: var(--theme_primary_color);
}

.img_message {
    width: 120px;
    height: 120px;
}

.sample_message {
    width: 32vw;
    height: 32vw;
}

.radio_text {
    width: 100% !important;
    height: auto !important;
    border: 1px solid grey;
    padding: 8px;
    padding-left: 64px;
    border-radius: 16px;
    margin-bottom: 8px !important;
}

.radio_text .input_value_check {
    transform: translate(-20px, 4px) !important;
}

.radio_choices {
    text-align: left;
    margin-top: 12px;
}

.radio_item {
    margin: 0px 2px;
    cursor: pointer;
}

.radio_item .input_value_check {
    position: absolute;
    transform: translate(20px, 80px);
}

.old_message_text {
    color: #A7A7A7;
}

.input_wrapper {
    display: flex;
    // border: 1px solid #A7A7A7;
    border-radius: 8px;
    padding: 4px 8px;
    align-items: center;
    margin-top: auto;
    box-shadow: 0px 0px 4px #00000020
}

.input_wrapper textarea {
    color: black;
}

.user_input_btn {
    border-radius: 100px;
    background-color: black;
    color: white;
    width: 64px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_input_btn {
    --padding-start: 0.5em;
    --padding-end: 0.5em;
}

.metric_box {
    margin: 12px 0;
    background-color: var(--theme_bg_color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: 16px;
}

.metric_box p {
    margin: 0;
}

.metric_box .metric {
    margin: 0;
    font-weight: bold;
    font-size: 40px;
}

.metric_box .metric.main {
    font-size: 52px;
    color: var(--theme_primary_color);
}

.metric_box .metric .sar {
    font-weight: normal;
    font-size: 12px;
}

.dzyni_box {
    margin: 12px 0;
    background-color: var(--theme_bg_color);
    border-radius: 16px;
    padding: 16px;
}

.dzyni_box p {
    color: black;
    margin: 0;
    font-size: 20px;
}

.dzyni_box .big {
    color: var(--theme_primary_color);
    font-weight: bold;
    font-size: 56px;
    margin: -6px 0 -12px;
    white-space: nowrap;
}

.dzyni_box .big span {
    color: black;
    font-weight: normal;
    font-size: 12px;
}

.dzyni_box .flex_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    white-space: pre-wrap;
}

.dzyni_box .box_button {
    border-radius: 100px;
    padding: 4px;
    color: var(--theme_primary_color);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    display: inline-flex;
    width: 100px;
    background-color: #fff;
    margin-top: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.dzyni_box .box_button:hover {
    background-color: #efefef;
}

.dzyni_box.inline_box {
    width: 90%;
    display: inline-block;
    margin-left: 8px;
    padding: 0;
}

.dzyni_box.inline_box p {
    font-size: 14px;
}

.dzyni_box .box_title {
    font-size: 20px !important;
    font-weight: bold;
}

.dzyni_box .box_desc {
    margin: 8px 0 16px;
}

.dzyni_box.inline_box img {
    width: 100px;
}

.dzyni_box.inline_box .flex_row {
    padding: 16px;
}

.dzyni_box.inline_box.full_box {
    width: 100%;
    background: linear-gradient(90deg, #6510fb30, #6510FB);
}

.dzyni_box.inline_box.full_box p {
    color: white;
}

.dzyni_box.inline_box.full_box .box_button {
    white-space: nowrap;
    width: 150px;
    font-size: 12px;
}


.uncompleted_tasks_wrapper {
    overflow-x: scroll;
    white-space: nowrap;
}

.chat_button {
    height: 40px;
    background-color: var(--theme_bg_color);
    width: 50% !important;
    color: var(--theme_primary_color);
}

.chat_button svg {
    margin-right: 4px;
    fill: var(--theme_primary_color);
}

.chat_button:hover {
    background-color: var(--theme_bg_color);
}

.payments_table {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payments_table .price {
    font-weight: bold;
    color: var(--theme_primary_color);
}

.payment_status_icon {
    margin-left: 4px;
}


// rangle selector
.date_range_selector {
    background-color: var(--theme_bg_color);
    border-radius: 100px;
    display: flex;
    color: #000;
    justify-content: space-evenly;
    font-weight: bold;
    padding: 8px;
}

.date_range_item {
    width: 30%;
    text-align: center;
    color: #747474;
    padding: 4px;
    cursor: pointer;
}

.date_range_item.active {
    background-color: var(--theme_primary_color);
    color: white;
    border-radius: 100px;
}

// count_box 
.count_box {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    background-color: var(--theme_bg_color);
    color: var(--theme_primary_color) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    top: 10px;
    right: 16px;
    border-radius: 5px;
    background-color: #c2c2c2;
    color: #c2c2c2;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #c2c2c2;
    color: #c2c2c2;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #c2c2c2;
    color: #c2c2c2;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: #c2c2c2;
    }

    50%,
    100% {
        background-color: black;
    }
}


.alert-checkbox-icon.sc-ion-alert-md,
.alert-radio-icon.sc-ion-alert-md {
    right: 26px;
    left: 0;
}

#ion-overlay-1>app-add-service>ion-content>ion-row:nth-child(3)>ion-col:nth-child(2)>div>ion-list>ion-item {
    --color: black;
}

.incomplete-profile {
    --max-width: 460px !important;
}

// proposal submit
.proposal_input_wrapper {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    margin-top: 16px;
}

.price_calculator {
    font-size: 12px;
    color: rgb(54, 54, 54);
    white-space: nowrap;
    margin-left: 12px;
    margin-right: 12px;
    display: none;
    direction: rtl;
}

.price_total {
    font-weight: bold;
    color: var(--theme_primary_color);
}

.proposal_input_wrapper button {
    background-color: black;
    color: white;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    padding: 0px 24px;
}

.proposal_input_wrapper button:hover {
    background-color: var(--theme_primary_color);
}

.proposal_input_wrapper .submitted_input {
    border: 1px solid var(--border_color);
    border-radius: 12px;
    margin-left: 4px;
}

.proposal_inner_input_wrapper {
    border-radius: 12px;
    margin-left: 4px;
    border: none;
    background-color: #F5F5F5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.proposal_inner_input_wrapper input {
    background-color: transparent;
}

.service_imgs_wrapper {
    overflow-x: scroll;
    width: 100%;
    white-space: nowrap;
}

.service_img {
    width: 256px;
    height: 128px;
    border-radius: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    vertical-align: middle;
    margin-left: 4px;
    box-shadow: 0px 0px 4px #00000020;
}

.service_input,
.service_img {
    background-color: var(--theme_bg_color);
    color: black;
    border-radius: 8px;
}

.service_input_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}

.service_input_wrapper svg {
    border-radius: 8px;
    background: var(--theme_primary_color);
    width: 48px;
    height: 40px;
    margin: 4px;
}

.service_submit_date_wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.service_submit_date_wrapper div {
    border: 1px solid grey;
    border-radius: 8px;
    width: 100%;
    margin: 0px 4px;
    text-align: center;
    padding: 6px;
}

.service_submit_date_wrapper div.active {
    background-color: var(--theme_primary_color);
    color: white;
    border: none;
}

.service_img:hover {
    background-color: rgb(193, 193, 193);
}

.image-view-modal {
    background: transparent !important;
    --background: #000000cc !important;
}

.image-view-modal, .add-service-modal {
    --width: 100% !important;
}

.service-details-modal {
    // --width: 100% !important;
}

.rm_design_img {
    width: 32px;
    border-radius: 32px;
    background-color: rgb(243, 243, 243);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    margin-top: 4px;
}

.rm_design_img path {
    color: red;
}






// Service
.services_wrapper {
    white-space: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.services_wrapper::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.service_item {
    display: inline-block;
    padding: 16px;
    border-radius: 20px;
    border: 1px solid #d4d4d4;
    margin-left: 20px;
    width: 70vw;
    color: black;
}

.service_item p {
    margin: 0;
    text-align: right;
    white-space-collapse: collapse;
}

.service_user {
    display: flex;
    align-items: center;
}

.service_user .user_icon {
    width: 32px;
    height: 32px;
    margin-left: 4px;
}

.service_user_name {
    font-size: 12px;
    font-weight: bold;
}

.service_description {
    display: flex;
    align-items: center;
}

.service_activate_div {
    background-color: var(--theme_bg_color);
    border-radius: 16px;
}

.service_order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 0px;
    padding-top: 12px;
    border-top: 1px solid #d4d4d4;
    font-weight: bold;
}

.service_order p {
    color: var(--theme_primary_color) !important;
}

.primary_text {
    font-weight: bold;
    color: var(--theme_primary_color) !important;
}

// designer_item
.designers_wrapper {
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.designers_wrapper::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.designer_item {
    width: 36vw;
    height: 36vw;
    border-radius: 20px;
    margin: 4px 6px;
    border: none;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: 0px 0px 4px #00000020;
}

.designer_item:hover {
    box-shadow: 0px 0px 8px #00000050;
    transform: scale(1.01);
}

.designer_item p {
    margin: 0;
    color: black !important;
    font-weight: bold;
}

.designer_info {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #ffffffbf;
    border-radius: 100px;
    padding: 4px 20px;
    margin-bottom: 8px;
    // box-shadow: 0px 0px 8px #00000050;
    width: 90%;
}

.designer_item_img {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-left: 4px;
}

.designer_item .designer_name {
    margin-bottom: -4px;
    font-size: 12px;
}

.designer_item .designer_city {
    font-size: 12px;
    font-weight: normal;
}


// chat styles

.chat_title {
    color: #8e8e8e;
    margin: 0;
    font-weight: bold;
}

.chat_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 90px;
    cursor: pointer;
    // padding: 0px 0 12px;
    padding: 12px;
    padding-left: 20px;
    transition: all 0.3s ease;
    background-color: var(--theme_bg_color);
    border-radius: 16px;
    margin-bottom: 8px;
}

.chat_item:hover {
    background-color: #e9e9e9;
}

.chat_user_header {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    direction: rtl;
}

.chat_request_title {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    margin-top: -4px;
    color: var(--theme_primary_color) !important;
}

.chat_unread_count {
    margin-right: auto;
    color: white !important;
    background-color: var(--theme_primary_color);
    width: 20px;
    height: 20px;
    border-radius: 32px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -3px;
}

.chat_msg_date {
    color: black;
}

.last_message {
    font-weight: normal;
    font-size: 12px;
    color: #8e8e8e !important;
    margin: 0;
    position: relative;
}

.swiper-pagination-bullet {
    background: var(--theme_bg_color) !important;
}

.swiper-pagination-bullet-active {
    background: var(--theme_primary_color) !important;
}

.alert-wrapper.sc-ion-alert-md {
    --background: white;
}

.alert-title.sc-ion-alert-md {
    color: black;
}

.imageViewer > .container > .footer > .description {
    font-family: 'IBM' !important;
    border-radius: 12px !important;
}

.actions-modal {
    &::part(handle) {
        background: var(--border_color);
        width: 150px;
        height: 6px;
    }

    &::part(content) {
        border-radius: 20px; // Here the border radius
    }
}

.actions-modal ion-content {
    --padding-start: 20px !important;
    --padding-end: 20px !important;
}

/* For desktop: */
@media only screen and (min-width: 768px) {
    .sample_message {
        width: 10vw;
        height: 10vw;
    }

    .pins {
        column-count: 2;
    }

    .uncompleted_tasks_wrapper {
        overflow: hidden;
        white-space: wrap;
    }

    .dzyni_box.inline_box {
        width: 100%;
        display: block;
        margin-left: 0px;
        padding: 0;
    }

    .back_button {
        margin-bottom: 0;
    }
}

/* For desktop: */
@media only screen and (min-width: 1025px) {
    .pins {
        column-count: 3;
        padding: 7px;
    }
}


// scrollbar syle
ion-content {
    //  --offset-bottom: auto !important;
    //  --overflow: auto;
    //  overflow: auto;

 &::-webkit-scrollbar {
     width: 5px;
     height: 5px;
 }

 &::-webkit-scrollbar-track {
     background: #e8e8e8;
 }

 &::-webkit-scrollbar-track:hover {
     background: #bebebe;
 }

 &::-webkit-scrollbar-thumb {
     background: #a4a4a4;
 }

  &::-webkit-scrollbar-thumb:hover {
      background: #939393;
  }

  .inner-scroll {
      scrollbar-width: thin;
  }
}